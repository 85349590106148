import "./PayNow.scss";
import Pay2 from './Pay2';
const PayNow = () => {
  return (
    <>
      <div className="pay-page-container">
        <div className="pay-pages">
          <div className="left">
          <div className="page-heading">Pay Now</div>
            <Pay2 />
        </div>
      </div>
      </div>
    </>
  );
};

export default PayNow
